<!--
 * @Author: your name
 * @Date: 2021-10-21 09:36:58
 * @LastEditTime: 2021-10-21 15:58:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cloud_classroom_pc\src\views\home\home.vue
-->
<template>
  <div class="layout">
    <div class="headers">
      <Header></Header>   
    </div>
    
    <div class="routerView">
      <router-view/>
    </div>
    <!-- <el-container>
      <el-header :height="100"><Header></Header></el-header>
      <el-main><router-view/></el-main>
    </el-container> -->
  </div>
  
</template>

<script>
import Header from './components/header.vue'
export default {
  data () {
    return {
      
    };
  },

  components: {
    Header
  },

  computed:{

  },

  mounted(){

  },

  methods: {
    
  }
}

</script>
<style lang='scss' scoped>
  .headers {
    width: 100%;
    position:fixed;
    z-index: 10;
  }
  .routerView{
    position: relative;
    top: 100px;
  }
</style>