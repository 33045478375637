<!--
 * @Author: your name
 * @Date: 2021-10-21 09:41:20
 * @LastEditTime: 2021-10-21 15:50:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cloud_classroom_pc\src\views\home\components\header.vue
-->
<template>
  <div class="header">
    <div class="container">
      <!-- LOGO -->
      <img class="logo" src="@/assets/logo_max.png" />
      <!-- 导航列表 -->
      <div class="tab-list">
        <div
          class="tab-list-item"
          v-for="(item, index) in tabList"
          :key="index"
          @click="goUrl(item.url)"
        >
          <div :class="isActive == item.url ? 'title' : ''">{{
            item.title
          }}</div>
        </div>
      </div>
      <!-- 用户个人中心 -->
      <el-dropdown
        v-if="token != '' && token != null"
        :hide-on-click="false"
        @command="dropdownMenu"
      >
        <span class="el-dropdown-link">
          <div class="user-info">
            <div class="message-warn">
              <div class="warn" v-if="messageId>0"></div>
              <el-avatar v-if="avatar != '' && avatar != null" size="medium" :src="avatar"></el-avatar>
              <el-avatar v-else size="medium" :src="avatar"></el-avatar>
            </div>
            <span class="user-name">{{ name }}</span>
          </div>
        </span>
        <el-dropdown-menu>
          <el-dropdown-item command="personalCenter">个人中心</el-dropdown-item>
          <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-else class="login" @click="dropdownMenu('loginOut')">登录</div>
    </div>
  </div>
</template>

<script>
import { getTimetableClassNotice } from "@/api/course";
export default {
  data() {
    return {
      logo: "",
      token: "",
      avatar: "",
      name: "",
      tabList: [
        {
          title: "首页",
          url: "/home",
        },
        {
          title: "课程中心",
          url: "/course",
        },
        {
          title: "直播中心",
          url: "/liveBroadcast",
        },
        {
          title: "题库练习",
          url: "/questionBank",
        },
        {
          title: "学习答疑",
          url: "/answer",
        },
        {
          title: "我的课程",
          url: "/myCourse",
        },
      ],
      messageId:0,//上课通知提醒
    };
  },

  components: {},

  computed: {
    isActive() {
      return this.$route.path;
    },
  },

  mounted() {
    this.updateInfo();
    this.getTimetable();
  },
  updated() {
    this.updateInfo();
    this.getTimetable();
  },
  methods: {

    //获取上课通知提醒
    getTimetable(){
      getTimetableClassNotice().then(res=>{
        if(res.code==200){
          this.messageId=res.data.classNoticeNumber
        }
      })
    },
    updateInfo() {
      this.token = localStorage.getItem("web-token");
      this.avatar = localStorage.getItem("avatar");
      this.name = localStorage.getItem("name");
    },
    goUrl(url) {
      this.$router.push(url);
    },
    dropdownMenu(command) {
      if (command == "loginOut") {
        localStorage.removeItem("web-token")
        this.$router.push("login");
      } else if (command == "personalCenter") {
        if (localStorage.getItem("web-token")) {
          this.$router.push("/personalCenter");
        } else {
          this.$router.push("/login");
        }
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.header {
  height: 100px;
  width: 100%;
  margin: 0;
  background-color: white;
  .container {
    // padding: 15px 0 15px 0;
    height: 100px;
    display: flex;
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
   
    .logo {
      width: 269px;
      height: 54px;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .login {
      font-size: 16px;
      font-weight: 400;
      line-height: 57px;
      color: #4394ff;
      cursor: pointer;
    }
    .tab-list {
      display: flex;
      flex: 1;
      margin: 0 81px 0 220px;
      // padding-bottom: 10px;
      height: 100%;
      z-index: 1;
      .tab-list-item {
        cursor: pointer;
        flex: 1;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .title {
          height: 100%;
          color: #4394ff;
          // background-color: ;
          line-height: 100px;
          border-bottom: 4px solid #4394ff;
        }
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      .avatar {
        width: 28px;
        height: 28px;
      }
      .user-name {
        margin-left: 5px;
      }
    }
  }
}
.el-dropdown {
  display: flex;
  align-items: center;
}
.message-warn{
  position: relative;
  .warn{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    background-color: red;
  }
}
</style>